import { NgClass, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { mixPanelEvent } from '@features/activities/data';
import { preOrderTrackEvents } from '@features/pre-order/data';
import { dialogAtom } from '@presentation/shared/dialog';
import { LocalizedComponent } from '../../base/localized.component';
import { PreOrderExternalProductRequestDialogComponent } from '../pre-order-external-product-request-dialog/pre-order-external-product-request-dialog.component';

@Component({
  selector: 'app-pre-order-external-product-request-card',
  templateUrl: './pre-order-external-product-request-card.component.html',
  styleUrls: ['./pre-order-external-product-request-card.component.scss'],
  imports: [NgFor, NgClass],
  standalone: true,
})
export class PreOrderExternalProductRequestCardComponent extends LocalizedComponent {
  countries = ['cn', 'uae', 'ksa'];

  public openPopup(): void {
    dialogAtom.open(PreOrderExternalProductRequestDialogComponent, {
      id: 'pre-order-external-product-request',
      minWidth: '1080px',
      backdropClass: 'backdrop-bg',
      maxHeight: '90vh',
    });

    mixPanelEvent(preOrderTrackEvents.clickPreOrderExternalProductOpenForm);
  }
}
