<div>
  <div>
    <!-- Product Request Card -->
  </div>
  <div>
    <loader [loading]="presenter.isLoading" size="md" alt="loading" id="loading"></loader>

    <div class="preorder-external__section no-gutters">
      <div>
        <app-pre-order-external-product-request-card></app-pre-order-external-product-request-card>
      </div>
      <div
        *ngIf="presenter.isLoaded && !presenter.isEmpty"
        class="col-12 d-flex flex-wrap justify-content-flex-start"
      >
        <app-product-card
          *ngFor="let product of presenter.data?.products"
          [variantGroup]="product"
          [isFromLockedCarousel]="false"
          [shouldShowStockDistribution]="product.primaryVariant?.availabilityInfo?.stockInfo"
        ></app-product-card>
      </div>
      <div class="preorder-external__section__empty" *ngIf="presenter.isEmpty">
        <!-- header -->
        <div class="preorder-external__section__empty__header d-flex justify-content-between">
          <p
            class="preorder-external__section__empty__header_text"
            [ngClass]="{ 'text-right': isLTR, 'text-left': isRTL }"
          >
            {{ trans('pre-order.external-products-empty.tab') }}
          </p>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="btn-group" dropdown>
              <span class="items-text">{{ trans('CATEGORY_PAGE.SHOW') }}</span>
              <button
                id="button-animated"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-animated"
              >
                <span class="selected-items-per-page">
                  12 {{ trans('CATEGORY_PAGE.PER_PAGE') }}
                </span>
                <i class="icon icon-dropdown" aria-hidden="true"></i>
              </button>
              <ul
                id="dropdown-animated"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-animated"
              >
                <li
                  *ngFor="let pageFilterSize of pagesFilterSize"
                  role="menuitem"
                  class="dropdown-item"
                  (click)="presenter.limit(pageFilterSize)"
                >
                  {{ pageFilterSize }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- content -->
        <div class="preorder-external__section__empty__content">
          <!-- icon -->
          <img
            class="preorder-external__section__empty__content_icon"
            src="assets/img/pre-order/product.svg"
            alt=" illustration of a box containing smaller boxes"
          />
          <!-- text -->
          <div class="preorder-external__section__empty__content__text">
            <p class="preorder-external__section__empty__content__text_heading">
              {{ trans('pre-order.external-products-empty.heading') }}
            </p>
            <p class="preorder-external__section__empty__content__text_description">
              {{ trans('pre-order.external-products-empty.description') }}
            </p>
            <img
              class="preorder-external__section__empty__content__text_arrow"
              src="assets/img/pre-order/back-arrow.svg"
              alt="arrow pointing towards external product request button"
              [ngClass]="{ flip: isLTR }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
