<!-- The card to open the pre-order external product request popup -->
<div class="product-request-card">
  <div class="product-request-card__content">
    <img class="product-request-card__content__icon" src="assets/img/pre-order/shipping-box.gif" />
    <p class="product-request-card__content__heading">
      {{ trans('pre-order.request-card.headingP1') }}
      <span class="highlight">
        {{ trans('pre-order.request-card.headingP2') }}
      </span>
      {{ trans('pre-order.request-card.headingP3') }}
    </p>
    <p class="product-request-card__content__description">
      {{ trans('pre-order.request-card.description') }}
    </p>
  </div>

  <button class="product-request-card__CTA" (click)="openPopup()">
    {{ trans('pre-order.request-card.cta') }}
  </button>
</div>

<div class="product-availability-card">
  <img class="product-availability-card__icon" src="assets/img/pre-order/clock.svg" />

  <div class="product-availability-card__content">
    <p
      [ngClass]="{
      'text-left': isLTR,
      'text-right': isRTL,
    }"
    >
      {{ trans('pre-order.availability-card.heading') }}
    </p>

    <div *ngFor="let country of countries">
      <img
        class="product-availability-card__content_flag"
        [src]="'assets/img/pre-order/' + country + '.svg'"
      />
      <span class="product-availability-card__content_country">{{
        trans('pre-order.availability-card.' + country + 'Title')
      }}</span>
      <img
        [ngClass]="{
        'rotate': isLTR,
      }"
        class="product-availability-card__content_arrow"
        src="assets/img/pre-order/arrow-left.svg"
      />
      <span class="product-availability-card__content_period">{{
        trans('pre-order.availability-card.' + country + 'Duration')
      }}</span>
    </div>
  </div>
</div>
