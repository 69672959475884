import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { country } from '@features/country/data';
import { heavyObject } from '@presentation/shared/utils';
import { GetCommercialCategoriesUseCase } from '../../../core/usecases/get-commercial-categories-usecase';
import { BaseComponent } from '../../base/base.component';
import { FormInputErrorComponent } from '../../form-input-error/form-input-error.component';
import { CustomValidator } from '../../shared/custom-validator';
import {
  PreOrderExternalProductRequestDialogPresenter,
  PreOrderExternalProductSideEffect,
  PreOrderExternalProductViewState,
} from './pre-order-external-product-request-dialog.presenter';

@Component({
  selector: 'app-pre-order-external-product-request-dialog',
  templateUrl: './pre-order-external-product-request-dialog.component.html',
  styleUrls: ['./pre-order-external-product-request-dialog.component.scss'],
  standalone: true,
  providers: [PreOrderExternalProductRequestDialogPresenter],
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatChipsModule,
    NgForOf,
    FormInputErrorComponent,
  ],
})
export class PreOrderExternalProductRequestDialogComponent extends BaseComponent<
  PreOrderExternalProductRequestDialogPresenter,
  PreOrderExternalProductViewState
> {
  public presenter: PreOrderExternalProductRequestDialogPresenter = inject(
    PreOrderExternalProductRequestDialogPresenter,
  );

  public form: UntypedFormGroup = new UntypedFormGroup({
    productLink: new FormControl('', [Validators.required, CustomValidator.url]),
    contactNumber: new FormControl('', [Validators.required]),
    productCategoryId: new FormControl('', [Validators.required]),
    requestedQuantity: new FormControl('', [
      Validators.required,
      CustomValidator.int,
      Validators.min(50),
    ]),
    expectedSellingPrice: new FormControl('', [CustomValidator.int, Validators.min(0)]),
    expectedDailyOrders: new FormControl('', [
      Validators.required,
      CustomValidator.int,
      Validators.min(0),
    ]),
    notes: new FormControl(''),
  });

  public isSubmitting = false;

  public categories: {
    id: string;
    name: string;
  }[] = [];

  constructor(private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase) {
    super();
  }

  public onInit(): void {
    this._getCommercialCategoriesUseCase.execute(country.code).subscribe((categories) => {
      this.categories = categories.root.children.map((category) => {
        return {
          id: category.key,
          name: this.localizedValue(category.value.name),
        };
      });
    });
  }

  public submit(): void {
    this.isSubmitting = true;

    const data = heavyObject(this.form.value);

    this.presenter.submit(data);
  }

  public onSideEffect(sideEffect: PreOrderExternalProductSideEffect): void {
    this.isSubmitting = false;
  }

  public closePopup(): void {
    this.closeDialog('pre-order-external-product-request');
  }
}
