import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BaseComponent } from '../../base/base.component';
import { EventWithType } from '../../base/base.presenter';
import { ProductCardComponent } from '../../products/products-v2/product-card/product-card.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { PAGES_FILTER_SIZE } from '../../shared/constants';
import { DEFAULT_SORTING_OPTION } from '../../shared/constants/category-products';
import { PreOrderExternalProductRequestCardComponent } from '../pre-order-external-product-request-card/pre-order-external-product-request-card.component';
import {
  PreOrderExternalProductsPresenter,
  PreOrderExternalProductsViewState,
} from './presentation/pre-order-external-products.presenter';

@Component({
  selector: 'app-pre-order-external-products',
  templateUrl: './pre-order-external-products.component.html',
  styleUrls: ['./pre-order-external-products.component.scss'],
  standalone: true,
  providers: [PreOrderExternalProductsPresenter],
  imports: [
    NgIf,
    NgFor,
    BsDropdownModule,
    LoaderComponent,
    ProductCardComponent,
    PreOrderExternalProductRequestCardComponent,
    NgClass,
  ],
})
export class PreOrderExternalProductsComponent extends BaseComponent<
  PreOrderExternalProductsPresenter,
  PreOrderExternalProductsViewState,
  EventWithType,
  {}
> {
  public presenter: PreOrderExternalProductsPresenter = inject(PreOrderExternalProductsPresenter);

  public selectedSortBy = DEFAULT_SORTING_OPTION;

  public pagesFilterSize = PAGES_FILTER_SIZE;

  public onInit(): void {
    this.presenter.fetch();
  }

  changeItemsOnPage(limit: number): void {
    this.presenter.limit(limit);
  }
}
