import { GenericObject } from '@mongez/reinforcements';

/**
 * Remove any falsy value except for 0
 */
export function heavyObject<T extends GenericObject>(obj: T): T {
  if (!obj) return obj;

  const result = {} as T;

  for (const key in obj) {
    if (obj[key] || obj[key] === 0) {
      result[key] = obj[key];
    }
  }

  return result;
}
