import type {
  CreateExternalProductPreOrderRequestUseCase,
  CreatePreOrderRequestUseCase,
  GetPreOrderRequestsUseCase,
  PreOrderExternalProductRequestPayload,
  PreOrderExternalProductsListParams,
  PreOrderInternalProductsListParams,
  PreOrderListParams,
  PreOrderProductsListData,
  PreOrderRepositoryContract,
  PreOrderRequestPayload,
  PreOrderRequestsListData,
} from '../types';

export const getPreOrderRequestsUseCase: GetPreOrderRequestsUseCase = async (
  repository: PreOrderRepositoryContract,
  params?: PreOrderListParams,
): Promise<PreOrderRequestsListData> => {
  return repository.list(params);
};

export const createPreOrderRequestUseCase: CreatePreOrderRequestUseCase = async (
  repository: PreOrderRepositoryContract,
  data: PreOrderRequestPayload,
) => {
  return repository.createPreOrderRequest(data);
};

// Pre Order Products
export const preOrderProductsListUseCase = async (
  repository: PreOrderRepositoryContract,
  params?: PreOrderInternalProductsListParams,
): Promise<PreOrderProductsListData> => {
  return repository.listProducts(params);
};

// External Pre Order Products
export const preOrderExternalProductsListUseCase = async (
  repository: PreOrderRepositoryContract,
  params?: PreOrderExternalProductsListParams,
): Promise<PreOrderProductsListData> => {
  return repository.listExternalProducts(params);
};

export const createExternalProductPreOrderRequestUseCase: CreateExternalProductPreOrderRequestUseCase =
  async (repository: PreOrderRepositoryContract, data: PreOrderExternalProductRequestPayload) => {
    return repository.createExternalProductPreOrderRequest(data);
  };
