import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PreOrderComponent } from './pre-order.component';

@NgModule({
  exports: [],
  imports: [CommonModule, BsDropdownModule.forRoot(), CollapseModule.forRoot()],
  providers: [
    provideRouter([
      {
        path: '',
        pathMatch: 'full',
        component: PreOrderComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]),
  ],
  declarations: [],
})
export class PreOrderModule {}
