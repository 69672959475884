import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isInt, isNumeric } from '@mongez/supportive-is';

export class CustomValidator {
  /**
   * Validate the given control value to be a valid URL.
   */
  public static url(control: AbstractControl): ValidationErrors | null {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const valid = urlPattern.test(control.value);
    return valid ? null : { url: true };
  }

  /**
   * Validate the given control value to be an integer.
   */
  public static int(control: AbstractControl): ValidationErrors | null {
    const valid = isInt(Number(control.value));
    return valid ? null : { int: true };
  }

  /**
   * Validate the given control value to be a number.
   * A valid number is either an integer or a float.
   */
  public static number(control: AbstractControl): ValidationErrors | null {
    const valid = isNumeric(Number(control.value));

    return valid ? null : { int: true };
  }

  /**
   * Validate the given control value to be a valid phone number.
   * @note this is not reviewed it, we will be checking it later
   */
  public static phoneNumber(countryCodes: string[]): ValidatorFn {
    const phonePatterns: { [key: string]: RegExp } = {
      KSA: /^(\+966|00966|966|0)?5\d{8}$/,
      EGP: /^(\+20|0020|20|0)?1[0125]\d{8}$/,
      UAE: /^(\+971|00971|971|0)?5[024568]\d{7}$/,
    };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null; // don't validate empty values to allow required validator to handle this
      }

      for (const code of countryCodes) {
        if (phonePatterns[code]?.test(control.value)) {
          return null; // valid phone number
        }
      }

      return { invalidPhoneNumber: { value: control.value } };
    };
  }
}
