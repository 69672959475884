import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { mixPanelEvent } from '@features/activities/data';
import { preOrderTrackEvents } from '@features/pre-order/data';
import { PreOrderActiveTab } from '@presentation/pre-order/types';
import { queryString } from '@presentation/shared/router';
import { scrollTo } from '@presentation/shared/utils';
import { LocalizedComponent } from '../base/localized.component';
import { LastCategorySalesReportCardComponent } from '../last-category/last-category-sales-report-card/last-category-sales-report-card.component';
import { PreorderBottomBannerComponent } from '../products/category-products/preorder-bottom-banner/preorder-bottom-banner.component';
import { PreorderRequestsSummaryComponent } from '../products/category-products/preorder-requests-summary/preorder-requests-summary.component';
import { PreorderTopBannerComponent } from '../products/category-products/preorder-top-banner/preorder-top-banner.component';
import { PreOrderExternalProductsComponent } from './pre-order-external-products/pre-order-external-products.component';
import { PreOrderProductsComponent } from './pre-order-products/pre-order-products.component';
import { PreOrderProgressComponent } from './pre-order-progress/pre-order-progress.component';

@Component({
  selector: 'app-pre-order',
  templateUrl: './pre-order.component.html',
  styleUrls: ['./pre-order.component.scss'],
  standalone: true,
  imports: [
    PreOrderProgressComponent,
    PreOrderExternalProductsComponent,
    PreOrderProductsComponent,
    PreorderTopBannerComponent,
    PreorderBottomBannerComponent,
    LastCategorySalesReportCardComponent,
    PreorderRequestsSummaryComponent,
    NgIf,
    NgClass,
  ],
})
export class PreOrderComponent extends LocalizedComponent implements OnInit {
  /**
   * We can add to the url the active tab we want to show to show the external products
   */
  public activeTab = (queryString.get('active') || 'external') as PreOrderActiveTab;

  public ngOnInit(): void {
    mixPanelEvent(preOrderTrackEvents.viewPage);
  }

  public scrollToProductsList(): void {
    this.activeTab = 'internal';
    scrollTo('pre-order-products-container');
  }

  public scrollToExternalProductsList(): void {
    this.activeTab = 'external';
    scrollTo('pre-order-products-container');
  }

  public changeTabTo(tab: PreOrderActiveTab): void {
    this.activeTab = tab;
    mixPanelEvent(
      tab === 'internal'
        ? preOrderTrackEvents.clickPreOrderProductsTab
        : preOrderTrackEvents.clickPreOrderExternalProductsTab,
    );
  }
}
