import { mixPanelEvent } from '@features/activities/data';
import { preOrderTrackEvents } from '@features/pre-order/data';
import {
  PreOrderExternalProductRequestPayload,
  PreOrderUseCaseResolver,
} from '@features/pre-order/domain';
import { trans } from '@mongez/localization';
import { dialogAtom } from '@presentation/shared/dialog';
import { parseError } from '@presentation/shared/error';
import { toastError, toastSuccess } from '@presentation/shared/toast';
import { BasePresenter, EventWithType } from '../../base/base.presenter';

export type PreOrderExternalProductViewState = PreOrderExternalProductRequestPayload;

export type PreOrderExternalProductViewEvent = EventWithType;

export type PreOrderExternalProductSideEffect =
  | {
      type: 'Error';
      error: string;
    }
  | {
      type: 'Success';
    };

export class PreOrderExternalProductRequestDialogPresenter extends BasePresenter<
  PreOrderExternalProductViewState,
  PreOrderExternalProductViewEvent,
  PreOrderExternalProductSideEffect
> {
  protected defaultViewState(): PreOrderExternalProductViewState {
    return {
      contactNumber: '',
      expectedDailyOrders: 0,
      productCategoryId: '',
      productLink: '',
      requestedQuantity: 0,
      expectedSellingPrice: 0,
      notes: '',
    };
  }

  public submit(data: PreOrderExternalProductViewState): void {
    PreOrderUseCaseResolver.createExternalProductPreOrderRequest(data)
      .then(() => {
        toastSuccess(trans('pre-order.externalProductCreated'));
        dialogAtom.close('pre-order-external-product-request');

        this.emitSideEffect({ type: 'Success' });

        mixPanelEvent(preOrderTrackEvents.submitPreOrderExternalProductForm, data);
      })
      .catch((error) => {
        error = parseError(error);
        toastError(error);

        this.emitSideEffect({ type: 'Error', error });
      });
  }
}
