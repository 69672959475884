import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocalizedComponent } from '../base/localized.component';

@Component({
  selector: 'app-form-input-error',
  templateUrl: './form-input-error.component.html',
  styleUrls: ['./form-input-error.component.scss'],
  imports: [NgIf],
  standalone: true,
})
export class FormInputErrorComponent extends LocalizedComponent implements OnInit {
  // Form control object
  @Input() control: FormControl;

  // error message
  @Input() error: string;

  // display message
  public errorMessage = '';

  public ngOnInit(): void {
    this.control?.statusChanges.subscribe(() => {
      // get control validation error type
      if (this.control.valid) {
        this.errorMessage = '';
        return;
      }

      // get control validation error type
      const { errors } = this.control;

      if (!errors) return;

      if (errors.required) {
        this.errorMessage = this.trans('validation.required');
      } else if (errors.min) {
        this.errorMessage = this.trans('validation.min', {
          min: errors.min.min,
        });
      } else if (errors.max) {
        this.errorMessage = this.trans('validation.max', {
          max: errors.max.max,
        });
      } else if (errors.url) {
        this.errorMessage = this.trans('validation.url');
      } else if (errors.email) {
        this.errorMessage = this.trans('validation.email');
      } else if (errors.int) {
        this.errorMessage = this.trans('validation.int');
      } else {
        this.errorMessage = this.error;
      }
    });
  }
}
