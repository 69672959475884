import {
  PreOrderExternalProductsListParams,
  PreOrderProductsListData,
  PreOrderUseCaseResolver,
} from '@features/pre-order/domain';
import { FetchPresenter } from '../../../base/fetch.presenter';
import { StatefulState } from '../../../base/stateful.presenter';

export type PreOrderExternalProductsViewState = StatefulState<PreOrderProductsListData, string>;

export class PreOrderExternalProductsPresenter extends FetchPresenter<PreOrderExternalProductsViewState> {
  protected queryParams: PreOrderExternalProductsListParams = {
    page: 1,
    pageSize: 12,
    sortBy: 'introducedAt',
    commercialCategoryId: undefined,
  };

  public get isEmpty(): boolean {
    return Number(this.data?.count) < 1;
  }

  /**
   * Start loading external products pre orders
   */
  public fetch(params: PreOrderExternalProductsListParams = this.queryParams): void {
    this.startLoading();

    this.queryParams = params;

    PreOrderUseCaseResolver.listExternalProducts(params)
      .then((result) => {
        this.success(result);
      })
      .catch((error) => {
        this.toastFailed(error);
      });
  }

  public gotoPage(page: number): void {
    this.fetch({
      ...this.queryParams,
      page,
    });
  }

  public sortBy(sortBy: string): void {
    this.fetch({
      ...this.queryParams,
      page: 1,
      sortBy,
    });
  }

  public limit(limit: number): void {
    this.fetch({
      ...this.queryParams,
      page: 1,
      pageSize: limit,
    });
  }

  public filterByCategory(commercialCategoryId: string): void {
    this.fetch({
      ...this.queryParams,
      page: 1,
      commercialCategoryId,
    });
  }
}
