<!-- Title and desc -->

<div
  class="preorder__external-form-header"
  [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL, 'reverse-row-dir': isRTL }"
>
  <button (click)="closePopup()" class="preorder__external-form-header__close-button">
    <img src="assets/img/wallet-icons/close-circle.svg" />
  </button>

  <div>
    <p class="preorder__external-form-header__content_heading">
      {{ trans('pre-order.external-dialog.heading') }}
    </p>
    <p class="preorder__external-form-header__content_description">
      {{ trans('pre-order.external-dialog.description') }}
    </p>
  </div>
</div>

<!-- Form and form actions -->
<form [formGroup]="form" class="preorder__external-form">
  <section [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }">
    <fieldset class="preorder__external-form__fieldset">
      <div class="preorder__external-form__fieldset__input-container">
        <label class="preorder__external-form__fieldset__input-container_label" for="productLink">{{
          trans('pre-order.external-dialog.productLink')
        }}</label>
        <input
          id="productLink"
          class="preorder__external-form__fieldset__input-container_input"
          formControlName="productLink"
          placeholder="www.website.com"
          [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }"
        />
        <app-form-input-error [control]="form.controls['productLink']"></app-form-input-error>
      </div>

      <mat-form-field
        appearance="outline"
        class="preorder__external-form__dropdown__container"
        [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }"
      >
        <mat-label class="body2--medium">{{
          trans('pre-order.external-dialog.productCategory')
        }}</mat-label>
        <mat-select formControlName="productCategoryId">
          <mat-option *ngFor="let category of categories" [value]="category.id">
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-form-input-error [control]="form.controls['productCategoryId']"></app-form-input-error>

      <div class="preorder__external-form__fieldset__input-container">
        <label
          class="preorder__external-form__fieldset__input-container_label"
          for="contactNumber"
          >{{ trans('pre-order.external-dialog.contactNumber') }}</label
        >
        <input
          id="contactNumber"
          class="preorder__external-form__fieldset__input-container_input"
          formControlName="contactNumber"
          placeholder="{{ trans('pre-order.external-dialog.contactNumber') }}"
          [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }"
        />
        <app-form-input-error [control]="form.controls['contactNumber']"></app-form-input-error>
      </div>
    </fieldset>

    <fieldset class="preorder__external-form__fieldset">
      <!-- separator -->
      <div class="separator"></div>
      <p class="preorder__external-form__fieldset_heading">
        {{ trans('pre-order.external-dialog.sectionHeading') }}
      </p>

      <div class="preorder__external-form__fieldset__input-container">
        <label
          class="preorder__external-form__fieldset__input-container_label"
          for="productCategory"
          >{{ trans('pre-order.external-dialog.requiredQuantity') }}</label
        >

        <input
          id="requestedQuantity"
          class="preorder__external-form__fieldset__input-container_input"
          formControlName="requestedQuantity"
          placeholder="{{ trans('pre-order.external-dialog.requiredQuantity') }}"
          [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }"
        />
        <app-form-input-error [control]="form.controls['requestedQuantity']"></app-form-input-error>
      </div>

      <div class="preorder__external-form__fieldset__input-container">
        <label
          class="preorder__external-form__fieldset__input-container_label"
          for="expectedDailyOrders"
          >{{ trans('pre-order.external-dialog.dailyOrders') }}</label
        >
        <input
          id="expectedDailyOrders"
          class="preorder__external-form__fieldset__input-container_input"
          formControlName="expectedDailyOrders"
          placeholder="{{ trans('pre-order.external-dialog.dailyOrders') }}"
          [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }"
        />
        <app-form-input-error
          [control]="form.controls['expectedDailyOrders']"
        ></app-form-input-error>
      </div>

      <div class="preorder__external-form__fieldset__input-container">
        <label
          class="preorder__external-form__fieldset__input-container_label"
          for="expectedSellingPrice"
          >{{ trans('pre-order.external-dialog.expectedSellingPrice') }}
          <span class="optional"> ({{ trans('pre-order.external-dialog.optional') }}) </span>
        </label>
        <input
          id="expectedSellingPrice"
          class="preorder__external-form__fieldset__input-container_input"
          formControlName="expectedSellingPrice"
          placeholder="{{ trans('pre-order.external-dialog.expectedSellingPrice') }}"
          [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }"
        />
        <span class="currency" [ngClass]="{ 'currency-right': isLTR }">SAR</span>
      </div>
      <app-form-input-error
        [control]="form.controls['expectedSellingPrice']"
      ></app-form-input-error>

      <div class="preorder__external-form__fieldset__input-container">
        <label class="preorder__external-form__fieldset__input-container_label" for="notes"
          >{{ trans('pre-order.external-dialog.notes') }}

          <span class="optional"> ({{ trans('pre-order.external-dialog.optional') }}) </span>
        </label>
        <textarea
          id="notes"
          class="preorder__external-form__fieldset__input-container_input text-area"
          formControlName="notes"
          [ngClass]="{ 'text-left': isLTR, 'text-right': isRTL }"
        ></textarea>
      </div>
    </fieldset>
  </section>

  <!--  form actions -->
  <section class="preorder__external-form-actions">
    <button
      type="submit"
      class="preorder__external-form-actions_btn --submit"
      [ngClass]="{ disabled: isSubmitting || form.invalid }"
      (click)="submit()"
      [disabled]="isSubmitting || form.invalid"
    >
      {{ trans('pre-order.external-dialog.submit') }}
    </button>

    <button
      type="button"
      class="preorder__external-form-actions_btn --close"
      (click)="closePopup()"
    >
      {{ trans('pre-order.external-dialog.close') }}
    </button>
  </section>
  <p class="preorder__external-form-footnote">
    {{ trans('pre-order.external-dialog.footnote') }}
  </p>
</form>
