<!-- Top Banner -->
<app-preorder-top-banner
  (scrollToExternalProducts)="scrollToExternalProductsList()"
  (scrollToProducts)="scrollToProductsList()"
></app-preorder-top-banner>

<div id="category-products" class="categories-page-container">
  <!-- Pre order summary -->
  <app-preorder-requests-summary></app-preorder-requests-summary>

  <div id="pre-order-products-container">
    <div class="separator-line"></div>
    <div class="tabs-container">
      <div
        class="tab"
        role="button"
        [ngClass]="{ active: activeTab === 'external' }"
        (click)="changeTabTo('external')"
      >
        {{ trans('pre-order.yourProducts') }}
      </div>
      <div
        class="tab"
        role="button"
        [ngClass]="{ active: activeTab === 'internal' }"
        (click)="changeTabTo('internal')"
      >
        {{ trans('pre-order.internalProducts') }}
      </div>
    </div>
    <!-- Internal Products -->
    <app-pre-order-products *ngIf="activeTab === 'internal'"></app-pre-order-products>
    <!-- External Products -->
    <app-pre-order-external-products
      *ngIf="activeTab === 'external'"
    ></app-pre-order-external-products>
  </div>
</div>
