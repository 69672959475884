import { resolvePreOrderRepository } from '@di/app';
import {
  PreOrderExternalProductRequestPayload,
  PreOrderExternalProductsListParams,
  PreOrderInternalProductsListParams,
  PreOrderListParams,
  PreOrderRequestPayload,
} from '../types';
import {
  createExternalProductPreOrderRequestUseCase,
  createPreOrderRequestUseCase,
  getPreOrderRequestsUseCase,
  preOrderExternalProductsListUseCase,
  preOrderProductsListUseCase,
} from '../use-cases/pre-order.use-case';

export class PreOrderUseCaseResolver {
  public static async list(
    params: PreOrderListParams,
  ): ReturnType<typeof getPreOrderRequestsUseCase> {
    return getPreOrderRequestsUseCase(resolvePreOrderRepository(), params);
  }

  public static async listProducts(
    params?: PreOrderInternalProductsListParams,
  ): ReturnType<typeof preOrderProductsListUseCase> {
    return preOrderProductsListUseCase(resolvePreOrderRepository(), params);
  }

  public static async listExternalProducts(
    params?: PreOrderExternalProductsListParams,
  ): ReturnType<typeof preOrderExternalProductsListUseCase> {
    return preOrderExternalProductsListUseCase(resolvePreOrderRepository(), params);
  }

  public static async createPreOrderRequest(
    data: PreOrderRequestPayload,
  ): ReturnType<typeof createPreOrderRequestUseCase> {
    return createPreOrderRequestUseCase(resolvePreOrderRepository(), data);
  }

  public static async createExternalProductPreOrderRequest(
    data: PreOrderExternalProductRequestPayload,
  ): ReturnType<typeof createExternalProductPreOrderRequestUseCase> {
    return createExternalProductPreOrderRequestUseCase(resolvePreOrderRepository(), data);
  }
}
